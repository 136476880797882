import React from 'react';
import { useEffect, useState } from 'react';
import "setimmediate";
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { Image, StyleSheet, View } from 'react-native';
import { Badge } from 'react-native-elements';
import { Provider as UserProvider } from './src/dataStore/userAccessContext';

/**
 *
 * Screens
 *
 **/

import AuthCheckScreen from './src/screens/AuthCheckScreen';
import AccountScreen from './src/screens/user/AccountScreen';
import CartScreen from './src/screens/shoping/CartScreen';
import HomeScreen from './src/screens/home/HomeScreen';
import OfferScreen from './src/screens/home/OffersScreen';
import SearchScreen from './src/screens/home/SearchScreen';
import SigninScreen from './src/screens/user/SigninScreen';
import SignupScreen from './src/screens/user/SignupScreen';
import { setNavigator } from './src/utils/NavigationRef';
import OrderScreen from './src/screens/shoping/OrderScreen';
import OrderDetailScreen from './src/screens/shoping/OrderDetails';
import PaymentScreen from './src/screens/shoping/PaymentScreen';
import FoodDetails from './src/screens/foods/FoodDetails';
import GruposDetails from './src/screens/foods/GruposDetails';
import RestaurantDetailScreen from './src/screens/foods/RestaurantDetails';
const moneda = Intl.NumberFormat('es-CO', { maximumSignificantDigits: 3 });

function CartIconTheme(props) {
  const { tintColor, focused } = props;
  const [productosCarrito, setProductosCarrito] = useState(-1);
  const [totalCarrito, setTotalCarrito] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const storedCarrito = JSON.parse(sessionStorage.getItem('carrito') ?? '[]');
      let cartQty = storedCarrito.reduce((acc,e) => acc + e.cantidad,0);
      if(cartQty != productosCarrito || (storedCarrito.length == 0 && productosCarrito > 0)){
        let cartTotal = storedCarrito.reduce((acc,e) => acc + e.cantidad*e.precioventaconiva,0);
        setProductosCarrito(cartQty);
        setTotalCarrito(cartTotal);
      }
    }, 1000);

    return () => clearInterval(timer); // Limpiar el temporizador al desmontar el componente
  }, []);

  let icon = focused ? require('./src/images/cart_icon.png') : require('./src/images/cart_n_icon.png');

  return (
    <View>
      <Image source={icon} style={styles.tabIcon} />
      {productosCarrito > 0 ? (
        <Badge value={productosCarrito} status="error" containerStyle={{ position: 'absolute', top: -4, right: -4 }} />
      ) :
      (
        <Badge value={0} status="warning" containerStyle={{ position: 'absolute', top: -4, right: -4 }} />
      )
      }
      {productosCarrito > 0 ? (
        <Badge value={'$ '+moneda.format(totalCarrito)} textStyle={{ fontSize: 15 }} status="success" containerStyle={{ fontSize: 21, position: 'fixed', bottom:10, left: '55.5%' }} />
      ) :
      <Badge value={'$ 0'} textStyle={{ fontSize: 15 }} status="success" containerStyle={{ fontSize: 21, position: 'fixed', bottom:10, left: '55.5%' }} />
     }
    </View>
  );
}

const switchNavigator = createSwitchNavigator({
  authCheck: AuthCheckScreen,
  homeStack: createBottomTabNavigator({
    Inicio: {
      screen: createStackNavigator({
        TopProducts: HomeScreen,
        Search: SearchScreen,
        FoodDetails: FoodDetails,
        RestaurantDetail: RestaurantDetailScreen,
        GruposDetails: GruposDetails,
      }),
      navigationOptions: {
        tabBarOptions: {
          activeTintColor: '#f15b5d',
        },
        tabBarIcon: ({ focused, tintColor }) => {
          let icon =
            focused == true
              ? require('./src/images/home_icon.png')
              : require('./src/images/home_n_icon.png');
          return <Image source={icon} style={styles.tabIcon} />;
        },
      },
    },
    Pagar: {
      screen: createStackNavigator({
        Shoping: CartScreen,
        Order: OrderScreen,
        Payment: PaymentScreen,
        OrderDetails: OrderDetailScreen,
      }),
      navigationOptions: {
        tabBarOptions: {
          activeTintColor: '#f15b5d',
        },
        tabBarIcon: ({ focused, tintColor }) => <CartIconTheme focused={focused} tintColor={tintColor}/>,
      },
    },
    Cuenta: {
      screen: AccountScreen,
      navigationOptions: {
        tabBarOptions: {
          activeTintColor: '#f15b5d',
        },
        tabBarIcon: ({ focused, tintColor }) => {
          let img =
            focused == true
              ? require('./src/images/account_icon.png')
              : require('./src/images/account_n_icon.png');
          return (
          <View>
            <Image source={img} style={styles.tabIcon} />
          </View>);
        },
      },
    },
  }),
  loginStack: {
    screen: createStackNavigator({
      Signin: SigninScreen,
      Signup: SignupScreen,
    }),
    navigationOptions: {
      tabBarIcon: ({ focused, tintColor }) => {
        let icon =
          focused == true
            ? require('./src/images/account_icon.png')
            : require('./src/images/account_n_icon.png');
        return <Image source={icon} style={styles.tabIcon} />;
      },
    },
  },
});

const styles = StyleSheet.create({
  tabIcon: {
    width: 30,
    height: 30,
  },
});

const App = createAppContainer(switchNavigator);

export default () => {
  return (
    <UserProvider>
      <App
        ref={(navigator) => {
          setNavigator(navigator);
        }}
      />
    </UserProvider>
  );
};
