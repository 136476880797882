import React, { useState, useEffect } from 'react';
import { View, Text, Modal, TextInput, StyleSheet, Picker } from 'react-native';
import { Button } from "react-native-elements";
import Select from 'react-select';
import ciudades from './ciudades.json';

const ClienteModal = ({ visible, onClose }) => {
  const [identificacion, setIdentificacion] = useState('');
  const [primerNombre, setPrimerNombre] = useState('');
  const [segundoNombre, setSegundoNombre] = useState('');
  const [primerApellido, setPrimerApellido] = useState('');
  const [segundoApellido, setSegundoApellido] = useState('');
  const [name, setname] = useState('');
  const [tipoDocumento, setTipoDocumento] = useState('cc');
  const [ciudadesAuth, setciudadesAuth] = useState([]);
  const [allZonas, setAllZonas] = useState([]);
  const [tipoFactura, setTipoFactura] = useState('pos');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [cityCode, setCityCode] = useState('');
  const [cityState, setCityState] = useState('');
  const [cityName, setCityName] = useState('');
  const [city, setCity] = useState('');
  const [zona, setZona] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Verifica si ya existen datos en el almacenamiento local
    const savedData = JSON.parse(localStorage.getItem('userData'));
    const config = JSON.parse(sessionStorage.getItem('configuracion'));
    const ciudadesAutorizadas = JSON.parse(config.ciudades);
    setciudadesAuth(ciudadesAutorizadas);
    const direccion_gps = localStorage.getItem('direccion_gps');
    if (savedData) {
      setIdentificacion(savedData.identificacion);
      setPrimerNombre(savedData.primerNombre);
      setSegundoNombre(savedData.segundoNombre);
      setPrimerApellido(savedData.primerApellido);
      setSegundoApellido(savedData.segundoApellido);
      setname(savedData.name);
      setTipoDocumento(savedData.tipoDocumento);
      setTipoFactura(savedData.tipoFactura);
      setEmail(savedData.email);
      setPhone(savedData.phone);
      setAddress(savedData.address);
      setAddress2(savedData.address2);
      setZona(savedData.zona);
      setCityName(savedData.city);
      setCityState(savedData.cityState);
      setCityCode(savedData.cityCode);
      buscarCiudad(savedData.cityName,savedData.cityState);
    }
    else{
      setAddress(direccion_gps);
    }

  }, []);

  const buscarCiudad = (cityName1, departamento) => {
    console.log(options);
    var ciudad = options.find((e) => e.value == cityName1+', '+departamento);
    if(ciudad){
      setCityCode(ciudad.code);
      setCityState(ciudad.value.split(',')[1].trim());
      setCityName(ciudad.value.split(',')[0]);
      setCity(ciudad);
    }else {
      setError('Ingrese una ciudad válida');
      return;
    }
  }

  const changeCity = (city) => {
    let zonas = ciudadesAuth.find((e) => e.name.toLowerCase() == city.name.toLowerCase());
    if(zonas){
      setAllZonas(zonas.zones);
    }
    setCity(city);
  }

  const handleSave = () => {
    if(tipoFactura != 'fe'){
      if (!name || !address || !phone || !address2 || !zona) {
        setError('Todos los campos son obligatorios');
        return;
      }
      // Validar el formato del número de teléfono
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(phone)) {
        setError('El número de teléfono debe tener 10 dígitos');
        return;
      }
    }
    else {
      if(tipoDocumento == 'nit'){
        if (!name) {
          setError('Llena los campos son obligatorios');
          return;
        }
      }
      else {
        if (!primerNombre || !primerApellido) {
          setError('Llena los campos son obligatorios');
          return;
        }
      }
      // Validar el formato de correo electrónico
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        setError('Ingrese un correo electrónico válido');
        return;
      }
      if (!city || !email || !phone || !address || !identificacion || !address2 || !zona) {
        setError('Llena los campos son obligatorios');
        return;
      }
    }
    // Validar la ciudad (solo como ejemplo, puedes agregar una lista completa de ciudades de Colombia)
    console.log(city);
    /*var code = options.find((ciudad) => ciudad.value == city.value);
    if(code){
      setCityCode(code.code);
    }else {
      setError('Ingrese una ciudad válida');
      return;
    }*/
    /*if (!city) {
      var city = {value:"Pereira, Risaralda",code:0};
      var code = {code:0};
    }*/
    // Guardar los datos en el almacenamiento local
    const userData = { identificacion, primerNombre, segundoNombre, primerApellido, segundoApellido, name, tipoDocumento: (tipoDocumento ?? 'cc'), tipoFactura: (tipoFactura ??  'pos'), email, phone, address, address2, zona, city, cityName:city.value ? city.value.split(',')[0] : '', cityCode:city.code ?? 0, cityState: city.value ? city.value.split(',')[1].trim() : '' };
    localStorage.setItem('userData', JSON.stringify(userData));

    // Cerrar el modal
    onClose(userData);
  };

  const options = ciudades.filter((e) => ciudadesAuth.find((d) => d.name.toLowerCase() == e.name.toLowerCase())).map((ciudad) => {
    return{
      label: ciudad.name+", "+ciudad.department.name,
      value: ciudad.name+", "+ciudad.department.name,
      name: ciudad.name,
      code: ciudad.code
   }
  });

  const zonas = allZonas.map((zona) => {
    return {
      label: zona.name,
      value: zona.name,
      code: zona.name,
      cost: zona.cost,
    }
  });

  return (
    <View style={styles.centeredView}>
      <View style={{maxHeight:"100%",overflow:"auto"}}>
        <View style={styles.modalView}>
          <Text style={styles.title}>{"Ingrese sus datos"}</Text>
          <Picker
            selectedValue={tipoFactura}
            style={styles.input}
            onValueChange={(itemValue) => setTipoFactura(itemValue)}
          >
            <Picker.Item label="Fac. Electrónica consumidor final" value="pos" />
            <Picker.Item label="Fac. Electrónica a nombre propio" value="fe" />
          </Picker>
          {tipoFactura === 'fe' ?
          <>
          <Picker
            selectedValue={tipoDocumento}
            style={styles.input}
            onValueChange={(itemValue) => setTipoDocumento(itemValue)}
          >
            <Picker.Item label="Cédula de Ciudadanía" value="cc" />
            <Picker.Item label="NIT" value="nit" />
            <Picker.Item label="Cédula de Ciudadanía" value="cc" />
            <Picker.Item label="Cédula de Extranjería" value="ce" />
            <Picker.Item label="Pasaporte" value="pasaporte" />
            <Picker.Item label="Registro Civil" value="rc" />
            <Picker.Item label="Tarjeta de Identidad" value="ti" />
          </Picker>
          <TextInput
            style={styles.input}
            placeholder="N° de Identifiación (*)"
            value={identificacion}
            onChangeText={setIdentificacion}
            autoCompleteType="dni"
          />
          {
          tipoDocumento !== 'nit' ? (
            <>
              <TextInput
                style={styles.input}
                placeholder="Primer Nombre (*)"
                value={primerNombre}
                onChangeText={setPrimerNombre}
                autoCompleteType="name"
              />
              <TextInput
                style={styles.input}
                placeholder="Segundo Nombre"
                value={segundoNombre}
                onChangeText={setSegundoNombre}
                autoCompleteType="name"
              />
              <TextInput
                style={styles.input}
                placeholder="Primer Apellido (*)"
                value={primerApellido}
                onChangeText={setPrimerApellido}
                autoCompleteType="name"
              />
              <TextInput
                style={styles.input}
                placeholder="Segundo Apellido"
                value={segundoApellido}
                onChangeText={setSegundoApellido}
                autoCompleteType="name"
              />
            </>
          ):
          (              <TextInput
                          style={styles.input}
                          placeholder="Razón Social (*)"
                          value={name}
                          onChangeText={setname}
                          autoCompleteType="name"
                        />)
              }
              </>
                        :
                        (              <TextInput
                                        style={styles.input}
                                        placeholder="Nombre Completo (*)"
                                        value={name}
                                        onChangeText={setname}
                                        autoCompleteType="name"
                                      />)

        }
        <TextInput
          style={styles.input}
          placeholder="Teléfono (*)"
          value={phone}
          onChangeText={setPhone}
          keyboardType="phone-pad"
        />
          {tipoFactura == 'fe' && (
              <TextInput
                style={styles.input}
                placeholder="Correo Electrónico (*)"
                value={email}
                onChangeText={setEmail}
                keyboardType="email-address"
                autoCompleteType="email"
              />
            )}
          <View style={{display:"grid", flexWrap:"wrap", width:'100%',zIndex:9999}}>
          <Select
            value={city}
            className=".r-width-13qz1uu"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: '100%',
                fontSize: 21,
                zIndex:99999
              }),
            }}
            onChange={(selectedOption) => changeCity(selectedOption)}
            options={options}
            placeholder="Ciudad (*)"
          />
          </View><br/>
          <TextInput
            style={styles.input}
            placeholder="Dirección (*)"
            value={address}
            onChangeText={setAddress}
            autoCompleteType="street-address"
          />
          <TextInput
            style={styles.input}
            placeholder="Desc. / Edf / Apto / Casa (*)"
            value={address2}
            onChangeText={setAddress2}
            autoCompleteType="street-address"
          />
          <View style={{display:"grid", flexWrap:"wrap", width:'100%',zIndex:9999}}>
          <Select
            value={zona}
            className=".r-width-13qz1uu"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: '100%',
                fontSize: 21,
                zIndex:99999
              }),
            }}
            onChange={(selectedOption) => setZona(selectedOption)}
            options={zonas}
            placeholder="Zona (*)"
          />
          </View><br/>

          {error ? <Text style={styles.error}>{error}</Text> : null}
          <View style={{display:"ruby", flexWrap:"wrap", zIndex:1}}>
            <Button type="clear" titleStyle={styles.titleStyle} buttonStyle={styles.buttonStyle} title="CONFIRMAR" onPress={handleSave} />
            <Button type="clear" titleStyle={styles.titleStyle} buttonStyle={styles.buttonStyleCancel} title="CANCELAR" onPress={() => onClose(false)} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 22,
  },
  titleStyle: {
    fontSize: 18,
    fontWeight: "400",
    color: "white",
  },
  buttonStyle: {
    margin: 5,
    backgroundColor: 'green',
    color: 'white'
  },
  buttonStyleCancel: {
    margin: 5,
    backgroundColor: '#C70000',
    color: 'white'
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    borderWidth: 1,
    fontSize: 24,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  error: {
    color: 'red',
    marginBottom: 10,
  },
});

export default ClienteModal;
