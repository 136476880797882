import React,{ useState, memo} from "react";
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";

import { Text, Rating } from "react-native-elements";
import AppButton from "../Buttons/AppButton";
import ButtonAddRemove from "../Buttons/AddRemoveButton";
import { Badge } from 'react-native-elements';

import { urlImage } from "../../utils/AppConst";

const deviceWidth = Math.round(Dimensions.get("window").width);
const moneda = Intl.NumberFormat('es-CO', { maximumSignificantDigits: 3 });

const FoodCard = memo(
  ({
  size,
  data,
  onSelect,
  disable = false,
  onAddToCart,
  canAdd = true,
  didAddRemove,
  cartItems
}) => {
  const { item } = data;

  const { idproducto, descripcion, foto, description, gustos, precioventaconiva, readyTime, codigo_grupo_2 } = item;
  const [qtyLocal, changeQtyLocal] = useState(1);

  let isAdded = false;
  let currentQty = 1;
  let totalQty = 0;

  let image = foto ?? "";

  function mostrarFoto() {
    //let input = document.frmConvert.hex.value.replace(/[^A-Fa-f0-9]/g, "");
    if (foto % 2) {
        console.log("cleaned hex string length is odd.");
        return;
    }

    let binary = new Array();
    for (let i = 0; i < foto.length / 2; i++) {
        let h = foto.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);
    let img = document.querySelector('.heximage');

    return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    //document.body.appendChild(img)
}

  const didAddItem = () => {
    onAddToCart();
  };

  const didRemoveItem = () => {};

  //const cartItems = JSON.parse(sessionStorage.getItem("carrito") ?? "[]");

  const mediumCard = () => {
    return (
      <View style={styles.root}>
        <TouchableOpacity onPress={() => onSelect(item)}>
          <Image style={styles.foodImage} source={{ uri: mostrarFoto() }} />
        </TouchableOpacity>
        <Text style={styles.title}>{descripcion}</Text>
      </View>
    );
  };

  const smallCard = () => {
    return (
      <TouchableOpacity
        style={smallStyles.smallCard}
        onPress={() => onSelect(item)}
        disabled={disable}
      >
        <Image style={smallStyles.foodImageSmall} source={{ uri: mostrarFoto() }} />
        <View style={smallStyles.productInfo}>
          <Text style={smallStyles.title}>{descripcion}</Text>
          <Text style={smallStyles.resturentTitle}>
          {cartItems && gustos.length < 1 &&
            cartItems.map((item) => {
              if (item.idproducto.toString() === idproducto.toString() && item.cantidad > 0) {
                return item.gustos_selected ? item.gustos_selected.map((e,i) => {return(
                  i != 0 ? ", "+e.descripcion.toString() : e.descripcion.toString()
                )}) : "";
              }
            })}
          </Text>
        </View>
        <View style={smallStyles.shopView}>
        {cartItems &&
          cartItems.map((item) => {
            if (item.idproducto.toString() === idproducto.toString() && item.cantidad > 0) {
              isAdded = true;
              currentQty = item.cantidad;
              totalQty += item.cantidad;
            }
          })}
          <Text style={smallStyles.productSize}>$ {moneda.format(precioventaconiva*(currentQty > 0 ? currentQty : 1))}</Text>

          {canAdd && !isAdded && gustos.length < 1 ? (
            <AppButton
              title="Agregar"
              width={70}
              onTap={() => onAddToCart(data.item)}
            />
          )
          :
          !isAdded &&( <AppButton
            title="Agregar"
            color="#3CB371"
            width={70}
            onTap={() => onAddToCart(data.item)}
          />
        ) }

          {isAdded && gustos.length < 1 ? (
            <View style={styles.countView}>
              <ButtonAddRemove
                title="-"
                onTap={() => didAddRemove(data.item, --currentQty)}
              />

              <Text
                h4
                style={{ alignSelf: "center", margin: 5, fontWeight: "600" }}
              >
                {currentQty}
              </Text>
              <ButtonAddRemove
                title="+"
                onTap={() => didAddRemove(data.item, ++currentQty)}
              />
            </View>
            )
            :
            isAdded && (
              <View style={styles.countView}>
              <Badge value={totalQty} status="warning" containerStyle={{ position: 'absolute', top: -4, right: -4 }} />
               <AppButton
              title="Agregar"
              color="#3CB371"
              width={70}
              onTap={() => onAddToCart(data.item)}
              />
              </View>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  const smallCardWithoutPic = () => {
    return (
      <TouchableOpacity
        style={smallStyles.smallCard2}
        onPress={() => onSelect(item)}
        disabled={disable}
      >
      {cartItems &&
        cartItems.map((item) => {
          if (item.idproducto.toString() === idproducto.toString() && item.cantidad > 0) {
            isAdded = true;
            currentQty = item.cantidad;
          }
        })}
        <View style={smallStyles.productInfo}>
          <Text style={smallStyles.productSize}>$ {moneda.format(precioventaconiva*(qtyLocal > 0 ? qtyLocal : 1))}</Text>
        </View>
        {(
          <View style={styles.countView}>
            <ButtonAddRemove
              title="-"
              onTap={() => qtyLocal > 1 && changeQtyLocal(qtyLocal-1)}
            />

            <Text
              h4
              style={{ alignSelf: "center", margin: 5, fontWeight: "600" }}
            >
              {qtyLocal}
            </Text>
            <ButtonAddRemove
              title="+"
              onTap={() => changeQtyLocal(qtyLocal+1)}
            />
          </View>
        )}
        <View style={smallStyles.shopView}>
          {canAdd /*&& !isAdded*/ && (
            <AppButton
              title="Agregar"
              width={70}
              onTap={() => onAddToCart(data.item, qtyLocal)}
            />
          )}

          {/*isAdded && (
            <View style={styles.countView}>
              <ButtonAddRemove
                title="-"
                onTap={() => didAddRemove(item, --currentQty)}
              />

              <Text
                h4
                style={{ alignSelf: "center", margin: 5, fontWeight: "600" }}
              >
                {currentQty}
              </Text>
              <ButtonAddRemove
                title="+"
                onTap={() => didAddRemove(item, ++currentQty)}
              />
            </View>
          )*/}
        </View>
      </TouchableOpacity>
    );
  };

  switch (size) {
    case "small": // wide card
      return smallCard();
    case "medium": // medium card
      return mediumCard();
    case "withoutpic":
      return smallCardWithoutPic();
    default:
      return mediumCard();
  }
},(prevProps, nextProps) => {
    return JSON.stringify(prevProps.cartItems ?? []) === JSON.stringify(nextProps.cartItems ?? []);
  },
);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 10,
  },
  foodImage: {
    borderRadius: 20,
    height: 220,
    width: deviceWidth - 30,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "rgba(81, 77, 77, 0.25)",
  },
  title: {
    fontSize: 14,
    fontWeight: "500",
    width: "100%",
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    textAlign: "left",
    marginTop: 10,
    color: "#636363",
  },
  countView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: 8,
  },
  countViewLocal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "left",
  },
});

const smallStyles = StyleSheet.create({
  smallCard: {
    flex: 1,
    height: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "white",
    borderColor: "#E5E5E5",
    borderWidth: 1,
    borderRadius: 10,
    margin: 10,
  },

  smallCard2: {
    flex: 2,
    height: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "white",
    borderColor: "#E5E5E5",
    borderWidth: 1,
    borderRadius: 10,
    margin: 5,
  },

  title: {
    fontSize: 16,
    fontWeight: "500",
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
  },
  resturentTitle: {
    fontSize: 16,
    display: "flex",
    color: "#565555",
  },
  price: {
    fontSize: 18,
    fontWeight: "400",
    display: "flex",
    color: "#EA5656",
  },
  foodImageSmall: {
    borderRadius: 10,
    height: 99,
    width: 99,
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(81, 77, 77, 0.25)",
    alignSelf: "center",
  },
  rating: {
    alignSelf: "flex-start",
  },
  productInfo: {
    flex: 1,
    padding: 5,
    justifyContent: "space-around",
    alignItems: "left",
    alignSelf: "left"
  },
  shopView: {
    justifyContent: "space-around",
    padding: 10,
    alignItems: "center",
  },
  productSize: {
    fontSize: 20,
    fontWeight: "600",
    color: "#848484",
  },
});

export default FoodCard;
