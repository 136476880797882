import React, { useContext, useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Dimensions,
  ImageBackground,
} from "react-native";
import { SafeAreaView } from "react-navigation";
import { Text, Button } from "react-native-elements";
import BackIcon from "../../images/back_arrow.png";
import GustosListView from "../../components/Listview/GustosListView";
import { urlImage } from "../../utils/AppConst";
import { navigate } from "../../utils/NavigationRef";
import FoodCard from "../../components/Cards/FoodCard";
import { Context as UserContext } from "../../dataStore/userAccessContext";

const screenWidth = Dimensions.get("window").width;

const ProductDetailScreen = ({ navigation }) => {
  const { state, onAddToCart, onAddGusto, onViewCart } = useContext(UserContext);
  const [gustosLocales, setGustoLocal] = useState([]);

  const { cartItems } = state;
  const { params } = navigation.state;

  useEffect(() => {
    onViewCart();
  }, []);

  const { _id, descripcion, foto, description, precioventaconiva, gustos, presentacion } = params;

  let image = foto;

  const item = { item: params };

  const didTapBack = () => {
    navigation.goBack();
  };

  const didAddToCard = (item, qty) => {
    if(qty > 0){
      onAddToCart({...item,['gustos_selected']:gustosLocales},qty, true);
      setGustoLocal([]);
      navigation.goBack();
      navigate("Shoping");
    }
    onAddToCart(item);
    //navigation.goBack();
  };

  const didAddRemove = (item, qty) => {
    onAddToCart(item, qty);
    /*if(!qty){
      navigation.goBack();
    }*/
  };

  const onGustoSelect = (codigo,value, added = false) => {
    if(added){
      onAddGusto(params, codigo, value);
      return;
    }
    var gustos = params.gustos ?? [];
    //var prodIndex = 0;//gustosLocales.findIndex((e) => e.idproducto == params.idproducto);
    //if (prodIndex != -1) {
      gustosTemp = gustosLocales ?? [];
    //}
    if (!value) {
      indexRemove = gustosTemp.findIndex((e) => e.codigo == codigo);
      gustosTemp.splice(indexRemove,1);
    }
    else {
      //gustos = prodIndex !== -1 ? (carrito[prodIndex].gustos ?? []) : gustos;
      var gustoIndex = gustos.findIndex((e) => e.codigo == codigo);
      gustosTemp.push(gustos[gustoIndex]);
    }
    setGustoLocal(gustosTemp);
  }

  function mostrarFoto() {
    //let input = document.frmConvert.hex.value.replace(/[^A-Fa-f0-9]/g, "");
    if (foto % 2) {
        console.log("cleaned hex string length is odd.");
        return;
    }

    let binary = new Array();
    for (let i = 0; i < foto.length / 2; i++) {
        let h = foto.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);
    let img = document.querySelector('.heximage');

    return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    //document.body.appendChild(img)
}

  return (
    <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        <TouchableOpacity onPress={() => didTapBack()}>
          <Image style={styles.imgIcon} source={BackIcon} />
        </TouchableOpacity>
        <Text h4 style={{ flex: 1, textAlign: "center", marginRight: 40 }}>
          {descripcion}
        </Text>
      </View>
      <View style={styles.listView}>
        <ImageBackground
          source={{
            uri: mostrarFoto(),
          }}
          style={styles.coverImage}
        >
          <View style={styles.foodInfo}>
            <Text style={styles.foodDetails}>{ presentacion && presentacion != "" ? presentacion : "ELIGE SEGÚN TUS GUSTOS" }</Text>
          </View>
        </ImageBackground>
        {gustos && gustos.length > 0 ?
          <GustosListView size="medium" gustos={gustos} gustosLocales={gustosLocales} didSelectItem={onGustoSelect} producto={params} />
        :
        <View style={smallStyles.listView}></View>
      }
        <View style={{ height: 80 }}>
          <FoodCard
            key={`${_id}`}
            size={"withoutpic"}
            data={item}
            cartItems={cartItems}
            disable={true}
            onSelect={() => {}}
            onAddToCart={didAddToCard}
            didAddRemove={didAddRemove}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  listView: {
    flex: 11,
  },
  coverImage: {
    width: screenWidth,
    height: 250,
    justifyContent: "flex-end",
  },

  imgIcon: {
    width: 40,
    height: 50,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },

  amountDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
  },
  foodTitle: {
    color: "white",
    fontWeight: "700",
  },
  foodInfo: {
    height: 80,
    backgroundColor: "rgba(0,0,0,0.6)",
    padding: 10,
  },
  foodDetails: {
    fontSize: 18,
    color: "white",
    fontWeight: "400",
    textAlign:"center"
  },
});

ProductDetailScreen.navigationOptions = () => {
  return {
    header: null,
  };
};

export default ProductDetailScreen;
