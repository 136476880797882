import React, { useContext, useEffect } from "react";
import { View, Text, StyleSheet, FlatList } from "react-native";
import FoodCard from "../Cards/FoodCard";
import { Context as UserContext } from "../../dataStore/userAccessContext";

const ProductListView = ({
  foods,
  size,
  horizontal,
  didSelectItem,
  didAddToCart,
  didAddRemove,
  disable
}) => {
  const { state, onViewCart} = useContext(UserContext);
  const { cartItems } = state;
  useEffect(() => {
    onViewCart();
  }, []);

  return (
    <FlatList
      horizontal={horizontal ? true : false}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      data={foods}
      extraData={cartItems}
      renderItem={(item, i) => (
        <FoodCard
          key={`prodList_${i}`}
          size={size}
          data={item}
          disable={disable}
          cartItems={cartItems}
          onSelect={didSelectItem}
          onAddToCart={didAddToCart}
          didAddRemove={didAddRemove}
        />
      )}
      keyExtractor={(item, key) => item.productoid.toString()}
    />
  );
};

export default ProductListView;
