import React, { useContext, useEffect } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Dimensions,
  ImageBackground,
} from "react-native";
import { SafeAreaView } from "react-navigation";
import { Text, Button } from "react-native-elements";
import BackIcon from "../../images/back_arrow.png";
import SearchIcon from "../../images/search.png";
import GruposListView from "../../components/Listview/GruposListView";
import { urlImage } from "../../utils/AppConst";
import { navigate } from "../../utils/NavigationRef";

import { Context as UserContext } from "../../dataStore/userAccessContext";

const screenWidth = Dimensions.get("window").width;

const RestaurantDetails = ({ navigation }) => {
  const { onAddToCart, onViewCart } = useContext(UserContext);

  const { params } = navigation.state;

  const {
    nombre,
    logo,
    description,
    direccion,
    foodType,
    grupos,
    sucursal,
    idsucursal,
  } = params;

  useEffect(() => {
    onViewCart();
    document.title = nombre;
  }, []);

  const didTapBack = () => {
    navigation.goBack();
  };

  const didSelectItem = (item) => {
    navigate("GruposDetails", item);
  };
  const buscarItem = () => {
    navigate("Search");
  };

  if (grupos.length > 0) {
    //navigate("GruposDetails", grupos[0]);
  }

  function mostrarFoto() {
    //let input = document.frmConvert.hex.value.replace(/[^A-Fa-f0-9]/g, "");
    if (logo % 2) {
        console.log("cleaned hex string length is odd.");
        return;
    }

    let binary = new Array();
    for (let i = 0; i < logo.length / 2; i++) {
        let h = logo.substr(i * 2, 2);
        binary[i] = parseInt(h, 16);
    }

    let byteArray = new Uint8Array(binary);
    let img = document.querySelector('.heximage');

    return window.URL.createObjectURL(new Blob([byteArray], { type: 'application/octet-stream' }));
    //document.body.appendChild(img)
}

  return (
    <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        {/*<TouchableOpacity onPress={() => didTapBack()}>
          <Image style={styles.imgIcon} source={BackIcon} />
        </TouchableOpacity>
      */}
        <Text h4 style={{ flex: 1, textAlign: "left", marginRight: 0 }}>
          {nombre}
        </Text>
        <TouchableOpacity onPress={() => buscarItem()}>
          <Image style={styles.searchIcon} source={SearchIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.listView}>
        <ImageBackground
          source={{
            uri: mostrarFoto(),
          }}
          style={styles.coverImage}
        >
          <View style={styles.foodInfo}>
            <Text
              style={{
                fontSize: 18,
                color: "#FFF",
                fontWeight: "500",
                marginTop: 5,
                textAlign:"center"
              }}
            >
              {direccion}
            </Text>
          </View>
        </ImageBackground>
        <GruposListView
          size="small"
          disable={false}
          grupos={grupos}
          didSelectItem={didSelectItem}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  listView: {
    flex: 11,
  },
  coverImage: {
    width: screenWidth,
    height: 220,
    justifyContent: "flex-end",
  },

  imgIcon: {
    width: 40,
    height: 50,
  },
  searchIcon: {
    width: 30,
    height: 30,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },

  amountDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
  },
  foodTitle: {
    color: "white",
    fontWeight: "700",
  },
  foodInfo: {
    height: 60,
    backgroundColor: "rgba(0,0,0,0.6)",
    padding: 10,
  },
  foodDetails: {
    fontSize: 18,
    color: "white",
    fontWeight: "400",
  },
});

RestaurantDetails.navigationOptions = () => {
  return {
    header: null,
  };
};

export default RestaurantDetails;
