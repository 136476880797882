import React from "react";
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
  CheckBox
} from "react-native";

import { Text, Rating } from "react-native-elements";
import AppButton from "../Buttons/AppButton";
import ButtonAddRemove from "../Buttons/AddRemoveButton";

import { urlImage } from "../../utils/AppConst";

const deviceWidth = Math.round(Dimensions.get("window").width);
const moneda = Intl.NumberFormat('es-CO', { maximumSignificantDigits: 3 });

const GustosCard = ({
  size,
  data,
  onSelect,
  disable = false,
  onAddToCart,
  canAdd = true,
  didAddRemove,
  itemChange,
  producto,
  gustosLocales,
  cartItems
}) => {
  const { item } = data;

  const { _id, descripcion, readyTime, seleccion, foto, codigo } = item;

  let currentQty = 1;

  let image = foto ?? "";

  const didAddItem = () => {
    onAddToCart();
  };

  const didRemoveItem = () => {};

  //const cartItems = JSON.parse(sessionStorage.getItem("carrito") ?? "[]");

  const mediumCard = () => {
    let isAdded = false;
    let prodAdded = false;
    var prodIndex = -1; //cartItems ? cartItems.findIndex((e) => e.idproducto == producto.idproducto) : -1;
    if (prodIndex != -1) {
      var temp_gustos = cartItems[prodIndex].gustos_selected ?? [];
      var indexGusto = temp_gustos.findIndex((e) => e.codigo == codigo);
      if (indexGusto != -1) {
        isAdded = true
      }
      prodAdded = true;
    }
    else {
      var temp_gustos = gustosLocales;//cartItems[prodIndex].gustos_selected ?? [];
      var indexGusto = temp_gustos.findIndex((e) => e.codigo == codigo);
      if (indexGusto != -1) {
        isAdded = true
      }
    }
    return (
      <TouchableOpacity
        style={smallStyles.smallCard}
        onPress={() => onSelect(codigo,!isAdded, prodAdded)}
        disabled={disable}
      >
      <View style={smallStyles.productInfo}>
          <Text style={styles.title}>{descripcion}</Text>
      </View>
      <View style={smallStyles.shopView}>
        <CheckBox
          value={isAdded}
          onValueChange={(e) => console.log(e)/*onSelect(codigo,!isAdded)*/}
        />
        </View>
      </TouchableOpacity>
    );
  };

  const smallCard = () => {
    return (
      <TouchableOpacity
        style={smallStyles.smallCard}
        onPress={() => onSelect(item)}
        disabled={disable}
      >
        <Image style={smallStyles.foodImageSmall} source={{ uri: image }} />
        <View style={smallStyles.productInfo}>
          <Text style={smallStyles.title}>{descripcion}</Text>
          <Text style={smallStyles.resturentTitle}>
            {codigo_grupo_2.toString().toUpperCase()}
          </Text>
        </View>
        <View style={smallStyles.shopView}>
          <Text style={smallStyles.productSize}>$ {moneda.format(precioventaconiva)}</Text>
          {cartItems &&
            cartItems.map((item) => {
              if (item.food._id.toString() === _id.toString()) {
                isAdded = true;
                currentQty = item.cantidad;
              }
            })}
          {canAdd && !isAdded && (
            <AppButton
              title="Adicionar"
              width={70}
              onTap={() => onAddToCart(data.item)}
            />
          )}

          {isAdded && (
            <View style={styles.countView}>
              <ButtonAddRemove
                title="-"
                onTap={() => didAddRemove(item, --currentQty)}
              />

              <Text
                h4
                style={{ alignSelf: "center", margin: 5, fontWeight: "600" }}
              >
                {currentQty}
              </Text>
              <ButtonAddRemove
                title="+"
                onTap={() => didAddRemove(item, ++currentQty)}
              />
            </View>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  switch (size) {
    case "small": // wide card
      return smallCard();
    case "medium": // medium card
      return mediumCard();
    default:
      return mediumCard();
  }
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: 10,
  },
  foodImage: {
    borderRadius: 20,
    height: 220,
    width: deviceWidth - 30,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "rgba(81, 77, 77, 0.25)",
  },
  title: {
    fontSize: 14,
    fontWeight: "500",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: 10,
    color: "#636363",
  },
  countView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: 8,
  },
});

const smallStyles = StyleSheet.create({
  smallCard: {
    flex: 1,
    height: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "white",
    borderColor: "#E5E5E5",
    borderWidth: 1,
    borderRadius: 10,
    margin: 10,
  },

  title: {
    fontSize: 16,
    fontWeight: "500",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  resturentTitle: {
    fontSize: 16,
    display: "flex",
    color: "#565555",
  },
  price: {
    fontSize: 18,
    fontWeight: "400",
    display: "flex",
    color: "#EA5656",
  },
  foodImageSmall: {
    borderRadius: 10,
    height: 99,
    width: 99,
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "rgba(81, 77, 77, 0.25)",
    alignSelf: "center",
  },
  rating: {
    alignSelf: "flex-start",
  },
  productInfo: {
    flex: 1,
    padding: 5,
    justifyContent: "space-around",
  },
  shopView: {
    justifyContent: "space-around",
    padding: 10,
    alignItems: "center",
  },
  productSize: {
    fontSize: 20,
    fontWeight: "600",
    color: "#848484",
  },
});

export default GustosCard;
