import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, View, StyleSheet, Image } from "react-native";
import { SafeAreaView } from "react-navigation";
import { Text, Button } from "react-native-elements";

import CartListView from "../../components/Listview/CartListView";
import AppButton from "../../components/Buttons/AppButton";
import { TouchableOpacity } from "react-native-gesture-handler";

import { Context as UserContext } from "../../dataStore/userAccessContext";
import { navigate } from "../../utils/NavigationRef";
import OrderIcon from "../../images/orders.png";
import PaymentTypePopup from "react-native-raw-bottom-sheet";
import ArrowIcon from "../../images/arrow_icon.png";
import ClienteModal from "./clienteModal";
import CryptoJS from 'crypto-js';

const moneda = Intl.NumberFormat('es-CO', { maximumSignificantDigits: 3 });
const CartScreen = ({ navigation }) => {
  const { state, onViewCart, onAddToCart, onCreateOrder } = useContext(
    UserContext
  );

  const { cartItems, orders, restaurants } = state;

  const popupRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [wompis, setWompis] = useState(null);

  useEffect(() => {
    console.log(orders);
    console.log("Goto Order Screen");
  }, [orders]);

  useEffect(() => {
    onViewCart();
    const config = JSON.parse(sessionStorage.getItem('configuracion'));
    let all_wp = JSON.parse(config.pasarela_wompi);
    all_wp = all_wp.filter((e) => e.enabled = true);
    setWompis(all_wp);
  }, []);


  const didTapOrderNow = (tipo = false) => {
    if(tipo){
      setIsOnline(true);
    }
    setLoading(true);
  };

  const generateSHA256 = async(cadenaConcatenada) => {
    const hash = CryptoJS.SHA256(cadenaConcatenada);
    const hashHex = hash.toString(CryptoJS.enc.Hex);
    return hashHex;
  }


   const getCliente = async (cliente) => {
    setLoading(false);
    if (!cliente) {
      return;
    }
    if (isOnline) {
      var actualWompi = {};
      if(wompis && wompis.length > 1){
        if(cliente.tipoDocumento == 'nit'){
          var wp_index = wompis.findIndex((e) => e.electronicInvoice == true);
          if(wp_index != -1){
            actualWompi = wompis[wp_index]
          }
          else {
            actualWompi = wompis[0];
          }
        }
        else{
          var wp_index = wompis.findIndex((e) => e.electronicInvoice == false);
          if(wp_index != -1){
            actualWompi = wompis[wp_index]
          }
          else {
            actualWompi = wompis[0];
          }
        }
      }
      else {
        actualWompi = wompis[0];
      }
      //var id = 'Maxx_' + (new Date()).getTime();
      var id = window.btoa(JSON.stringify(cartItems));
      var totalPagar = totalAmount()*100;
      var cadenaConcatenada = id+totalPagar+"COP"+actualWompi.signature;
      //Ejemplo
      const hashHex = await generateSHA256(cadenaConcatenada);
      console.log(hashHex);
      var configWompi = {
        currency: 'COP',
        amountInCents: totalPagar,
        reference: id,
        publicKey: actualWompi.publicKey,
        signature: {integrity : hashHex},
        redirectUrl: 'https://transaction-redirect.wompi.co/check', // Opcional
        customerData: { // Opcional
          email: cliente.email ?? "no-email@maxx247.com",
          fullName: cliente.name ?? "N/A",
          phoneNumber: cliente.phone ?? '0',
          phoneNumberPrefix: '+57',
          legalId: cliente.identificacion == '' ? '123456789' : cliente.identificacion,
          legalIdType: 'CC'
        },
        shippingAddress: { // Opcional
          addressLine1: cliente.address ?? "DIRECCION 1234 ",
          city: cliente.cityName ?? "Pereira",
          phoneNumber: cliente.phone ?? '3235028758',
          region: cliente.cityState ?? "Risaralda",
          country: "CO"
        }
      };
      console.log(configWompi);
      var checkout = new WidgetCheckout(configWompi);
      checkout.open(function (result) {
        var transaction = result.transaction;
        console.log("Transaction ID: ", transaction.id);
        console.log("Transaction object: ", transaction);
      });
    }
    else {
      onCreateOrder(cliente);
    }
  }

  const onAddItem = (item, qty) => {
    onAddToCart(item, qty);
  };

  const onRemoveItem = (item, qty) => {
    onAddToCart(item, qty);
  };

  const totalAmount = () => {
    let total = 0;
    if (cartItems !== undefined && cartItems.length > 0) {
      cartItems.map((item) => {
        let qty = item.cantidad;
        let price = item.precioventaconiva;
        total += qty * price;
      });
    }

    return total;
  };

  if(isLoading){
    return(
      <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
     <ClienteModal
          visible={isLoading}
          onClose={getCliente}
        />
        </SafeAreaView>
      );
  }

  return (
    <SafeAreaView style={styles.contentView} forceInset={{ top: "always" }}>
      <View style={styles.titleView}>
        <Text h4> Mi Carrito</Text>
        <TouchableOpacity
          style={{ alignItems: "center" }}
          onPress={() => {
            navigate("Order");
          }}
        >
          <Image source={OrderIcon} style={styles.imgIcon} />
        </TouchableOpacity>
      </View>
      <View style={styles.listView}>
        {cartItems !== undefined && cartItems.length > 0 ? (
          <CartListView
            cartItems={cartItems}
            onAddItem={onAddItem}
            onRemoveItem={onRemoveItem}
          />
        ) : (
          <View
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 28, fontWeight: "450", color: "#9C9696" }}>
              Tu carrito está vacío
            </Text>
          </View>
        )}
      </View>
      {cartItems !== undefined && cartItems.length > 0 && (
        <View style={styles.bottomView}>
          <View style={styles.amountDetails}>
            <Text style={{ fontSize: 18 }}> Total</Text>
            <Text style={{ fontSize: 18, fontWeight: "600" }}>
              $ {moneda.format(totalAmount())}
            </Text>
          </View>
          <AppButton
            height={50}
            title="Seguir Pidiendo"
            onTap={() => navigate("RestaurantDetail", restaurants[0])}
          /><br/>
          <AppButton
            height={50}
            color="#3CB371"
            title="Pedir ahora"
            onTap={() => popupRef.current.open()}
          />
        </View>
      )}
      <PaymentTypePopup
        ref={popupRef}
        closeOnDragDown={true}
        closeOnPressMask={false}
        height={250}
        customStyles={{
          wrapper: {
            backgroundColor: "transparent",
          },
          draggableIcon: {
            backgroundColor: "#000",
          },
          container: {
            justifyContent: "flex-start",
            alignItems: "center",
          },
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "99vw",
          }}
        >
          <View style={styles.amountDetails}>
            <Text style={{ fontSize: 18 }}> Total </Text>
            <Text style={{ fontSize: 18, fontWeight: "600" }}>
              $ {moneda.format(totalAmount())}
            </Text>
          </View>
          <TouchableOpacity
            onPress={() => {
              popupRef.current.close();
              didTapOrderNow();
            }}
          >
            <View style={styles.options}>
              <Text style={styles.optionsText}>Pago contraentrega</Text>
              <Image source={ArrowIcon} style={styles.icon} />
            </View>
          </TouchableOpacity>
          {
            wompis && wompis.length > 0 ?
          <TouchableOpacity
            onPress={() => {
              popupRef.current.close();
              didTapOrderNow(true);
            }}
          >
            <View style={styles.options}>
              <Text style={styles.optionsText}>Pago Electrónico</Text>
              <Image source={ArrowIcon} style={styles.icon} />
            </View>
          </TouchableOpacity>
          :
          <Text></Text>
        }
        </View>
      </PaymentTypePopup>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  contentView: {
    backgroundColor: "#F2F2F2",
    flex: 1,
    justifyContent: "space-between",
  },
  titleView: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
  },
  listView: {
    flex: 8,
  },
  bottomView: {
    flex: 3,
  },

  imgIcon: {
    width: 60,
    height: 60,
  },
  searchOptions: {
    display: "flex",
    height: 60,
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  topCategory: {
    height: 100,
    backgroundColor: "green",
  },

  amountDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
  },
  options: {
    display: "flex",
    height: 80,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingLeft: 20,
    paddingRight: 20,
    /*borderTopColor: "#DFDFDF",
    borderTopWidth: 0.5,
    borderBottomColor: "#DFDFDF",
    borderBottomWidth: 0.5,*/
  },
  optionsText: {
    fontSize: 18,
  },
  icon: {
    width: 40,
    height: 40,
  },
});

CartScreen.navigationOptions = () => {
  return {
    header: null,
  };
};

export default CartScreen;

/*
<View>
        <View style={styles.searchOptions}>

        </View>

        <View>

        </View>
      </View>*/
